export const gaEventNameEnum = {
    SIGNUP_VERIFY_OTP: 'verifyOtpSignup',
    LOGIN_VERIFY_OTP: 'verifyOtplogin',
    ACCEPT_IFA_INVITE: 'acceptIfaInvite',
    WEBINAR_PAGE_VIEWED: 'webinarPageViewed',
    WEBINAR_VIDEO_CLICKED: 'webinarVideoClicked',
    WEBINAR_LINK_CLICKED: 'webinarLinkClicked',
    CLICKED_LIST_VIEW_OPPORTUNITIES_MOBILE: 'clickedListViewOpportunitiesMobileWeb',
    CLICKED_INVEST_NOW: 'clickedInvestNow',
    ACCEPT_FUND_DECLARATION: 'acceptFundDeclaration',
    ACCEPT_DISCLAIMER: 'acceptDisclaimer',
    CONFIRM_INVESTMENT_DETAILS: 'confirmInvestmentDetails',
    VERIFY_DEMAT_DETAIL: 'verifyDematDetails',
    SUBMIT_DEMAT_DETAILS: 'submitDematDetails',
    ACCEPT_LLP_AGREEMENT: 'acceptLlpAgreement',
    DOWNLOAD_LLP_AGREEMENT: 'downloadLlpAgreement',
    SUBMIT_PROFILE_DETAILS: 'submitProfileDetails',
    ACCEPT_DISCOUNTING_AGREEMENT: 'acceptDiscountingAgreement',
    DOWNLOAD_DISCOUNTING_AGREEMENT: 'downloadDiscountingAgreement',
    VIEWED_ESIGN_PAGE: 'viewedEsignPage',
    SUCCESSFULLY_SIGNED_DOCUMENT: 'successfullySignedDocument',
    CANCELLED_SIGNED_DOCUMENT: 'cancelledSignedDocument',
    ERROR_SIGNED_DOCUMENT: 'errorSignedDocument',
    VIEWED_ONBOARDING_ESIGN_PAGE: 'viewedOnboardingEsignPage',
    SUCCESSFULLY_SIGNED_ONBOARDING_DOCUMENT: 'successfullySignedOnboardingDocument',
    CANCELLED_SIGNED_ONBOARDING_DOCUMENT: 'cancelledSignedOnboardingDocument',
    ERROR_SIGNED_ONBOARDING_DOCUMENT: 'errorSignedOnboardingDocument',
    VIEWED_PAYMENT_PAGE: 'viewedPaymentPage',
    PROCEED_WITH_ALTSAFE: 'proceedWithAltsafe',
    PROCEED_WITH_PG: 'proceedWithPg',
    PROCEED_WITH_UPI: 'proceedWithUpi',
    PROCEED_WITH_NETBANKING_FREECHANGE: 'proceedWithNetBankingFreecharge',
    PROCEED_WITH_NETBANKING_PAYU: 'proceedWithNetBankingPayu',
    EXIT_TRANSACTION: 'exitTransaction',
    VIEWED_INVESTMENT_SUCCESS_PAGE: 'viewedInvestmentSuccessPage',
    VIEWED_PENDING_PAYMENT_PAGE: 'viewedPendingPaymentPage',
    VIEWED_INVESTMENT_FAILED_PAGE: 'viewedInvestmentFailedPage',
    CLICKED_RETRY_PAYMENT: 'clickedRetryPayment',
    CLICKED_UPDATE_PREFERENCE_INVESTMENT_PAGE: 'clickedUpdatePreferenceInvestmentPage',
    UPCOMING_WEBINAR_PAGE_VIEWED: 'upcomingWebinarPageViewed',
    UPCOMING_WEBINAR_PAGE_CLICKED: 'upcomingWebinarClicked',
    VIEWED_QUESTIONNAIRE_PAGE: 'viewedQuestionnairePage',
    CLICKED_QUESTIONNAIRE_CONTINUE: 'clickQuestionnaireContinue',
    CLICKED_QUESTIONNAIRE_SKIP: 'clickQuestionnaireSkip',
    VIEWED_WELCOME_PAGE: 'viewedWelcomePage',
    CLICKED_BOOK_CONSULTATION_WELCOME_PAGE: 'clickBookConsultationWelcomePage',
    CLICKED_START_KYC_WELCOME_PAGE: 'clickStartKYCWelcomePage',
    CLICKED_SKIP_WELCOME_PAGE: 'clickSkipWelcomePage',
    VIEWED_WELCOME_BACK_PAGE: 'viewedWelcomeBackPage',
    CLICKED_BOOK_CONSULTATION_WELCOME_BACK_PAGE: 'clickBookConsultationWelcomeBackPage',
    CLICKED_START_KYC_WELCOME_BACK_PAGE: 'clickStartKYCWelcomeBackPage',
    CLICKED_COMPLETE_KYC_WELCOME_BACK_PAGE: 'clickCompleteKYCWelcomeBackPage',
    CLICKED_SKIP_WELCOME_BACK_PAGE: 'clickSkipWelcomeBackPage',
    CLICKED_CHAT_WITH_RM_MOBILE_WEB_NAVIGATION: 'clickedChatWithRmMobileWebNavigation',
    CLICKED_CHAT_WITH_RM_SIDENAV_MOBILE_WEB_NAVIGATION: 'clickedChatWithRmSideNavMobileWebNavigation',
    CLICKED_INVESTOR_INITIAL_MOBILE_WEB_NAVIGATION: 'clickedInvestorInitialMobileWebNavigation',
    CLICKED_HOME_PAGE_MORE_MOBILE_WEB_NAVIGATION: "clickedHomePageMoreMobileWebNavigation",
    CLICKED_RM_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedRelationshipManagerSideNavMobileWebNavigation",
    CLICKED_REFER_AND_EARN_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedReferandEarnSideNavMobileWebNavigation",
    CLICKED_ABOUT_US_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedAboutUsSideNavMobileWebNavigation",
    CLICKED_CAREERS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedCareersSideNavMobileWebNavigation",
    CLICKED_CONTACT_US_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedContactUsSideNavMobileWebNavigation",
    CLICKED_FAQS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedFaqsSideNavMobileWebNavigation",
    CLICKED_WEBINARS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedWebinarsSideNavMobileWebNavigation",
    CLICKED_BLOGS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedBlogsSideNavMobileWebNavigation",
    CLICKED_CREDIT_EVALUATION_PROCESS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedCreditEvaluationSideNavMobileWebNavigation",
    CLICKED_ABL_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedABLSideNavMobileWebNavigation",
    CLICKED_ID_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedIDSideNavMobileWebNavigation",
    CLICKED_VD_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedVentureDeptSideNavMobileWebNavigation",
    CLICKED_CD_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedCorporateDeptSideNavMobileWebNavigation",
    CLICKED_PRODUCTS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedProductsSideNavMobileWebNavigation",
    CLICKED_KNOWLEDGE_CENTER_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedKnowledgeCenterSideNavMobileWebNavigation",
    CLICKED_OUR_COMPANY_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedOurCompanySideNavMobileWebNavigation",
    CLICKED_THE_JOURNEY_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedTheJourneySideNavMobileWebNavigation",
    CLICKED_VALUES_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedValuesSideNavMobileWebNavigation",
    CLICKED_INVESTORS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedInvestorsSideNavMobileWebNavigation",
    CLICKED_LOGOUT_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedLogoutSideNavMobileWebNavigation",
    SUBMIT_BANK_DETAILS: "submitBankDeatils",
    VISITED_SIGNUP_PAGE: "visitedSignupPage",
    CLICKED_OPPORTUNITY_WITH_REINVEST_TAG: 'clickedOpportunityWithReinvestTag',
    INVESTMENT_FLOW_AUTO_REINVEST_ENABLED: 'investmentFlowAutoReinvestEnabled',
    INVESTMENT_FLOW_AUTO_REINVEST_SKIPPED: 'investmentFlowAutoReinvestSkipped',
    AUTO_REINVEST_TURN_ON: 'autoReinvestTurnOn',
    PAYMENT_SUCCESS_AUTO_REINVEST_ENABLED: 'paymentSuccessAutoReinvestEnabled',
    PAYMENT_SUCCESS_AUTO_REINVEST_SKIPPED: 'paymentSuccessAutoReinvestSkipped',
    REINVESTMENT_DETAILS_MODIFY_PREFERENCE: 'reinvestmentDetailsModifyPreference',
    REINVESTMENT_DETAILS_TURN_ON: 'reinvestmentDetailsTurnOn',
    REINVESTMENT_DETAILS_MODIFY_CONFIRM: 'reinvestmentDetailsModifyConfirm',
    REINVESTMENT_DETAILS_TURN_ON_CONFIRM: 'reinvestmentDetailsTurnOnConfirm',
    REINVESTMENT_DETAILS_MODIFY_CANCEL: 'reinvestmentDetailsModifyCancel',
    REINVESTMENT_DETAILS_TURN_ON_CANCEL: 'reinvestmentDetailsTurnOnCancel',
    REINVESTMENT_DETAILS_CARD_CLICK: 'reinvestmentDetailsCardClick',
    REINVESTMENT_DASHBOARD_CARD_CLICK: 'reinvestmentDashboardCardClick',
    DASHBOARD_REINVESTMENT_BANNER: 'dashboardReinvestmentBanner',
    INVESTMENT_DETAILS_VIEW_REINVEST_DETAILS: 'investmentDetailsViewReinvestDetails',
    OPPORTUNITY_LISTING_REINVESTMENT_BANNER: 'opportunityListingReinvestmentBanner',
    VIEW_REINVESTMENT_DASHBOARD: 'viewReinvestmentDashboard',
    VIEW_REINVESTMENT_DETAILS: 'viewReinvestmentDetails',
    VIEW_INVESTMENT_DETAILS: 'viewInvestmentDetails',
    VIEW_INVESTMENT_LIST: 'viewInvestmentList',
    CLICKED_APP_DOWNLOAD_NUDGE: 'clickedAppDownloadNudge',
    CANCELLED_APP_DOWNLOAD_NUDGE: 'cancelledAppDownloadNudge',
    CLICKED_START_KYC: "clickedStartKyc",
    CLICKED_CONTINUE_AS_INDIVIDUAL: "clickedContinueAsIndividual",
    CLICKED_CONTINUE_AS_NRI: "clickedContinueAsNri",
    CLICKED_CONFIRM_PAN: "clickedConfirmPan",
    CLICKED_PROCEED_WITH_DIGILOCKER: "clickedProceedWithDigilocker",
    CLICKED_PROCEED_WITH_DOCUMENT_UPLOAD: "clickedProceedWithDocumentUpload",
    SUCCESSFULLY_FETCHED_AADHAR_DETAILS_DIGILOCKER: "successfullyFetchedAdharDetailsDigilocker",
    CLICKED_PROCEED_WITH_DOCUMENT_UPLOAD_OUTSIDE_INDIA: "clickedProceedWithDocumentUploadOutsideIndia",
    CLICKED_PROCEED_WITH_DOCUMENT_UPLOAD_NO_CONNECT_DIGILOCKER: "clickedProceedWithDocumentUploadNoConnectDigilocker",
    CLICKED_PROCEED_WITH_DOCUMENT_UPLOAD_NO_RESPONSE: "clickedProceedWithDocumentUploadNoResponse",
    CLICKED_SWITCH_TO_DIGILOCKER: "clickedSwitchToDigilocker",
    CLICKED_CONTINUE_SELECT_DOC_TYPE: "clickedContinueSelectDocType",
    CLICKED_CHANGE_DOC_TYPE: "clickedChangeDocType",
    CLICKED_TO_UPLOAD_ADDRESS_DOCUMENT: "clickedToUploadAddressDocument",
    CLICKED_REUPLOAD_ADDRESS_DOCUMENT: "clickedReuploadAddressDocument",
    CLICKED_CONTINUE_DOC_SUBMIT: "clickedContinueDocSubmit",
    CLICKED_ADD_BANK_ACCOUNT_HV: "clickedAddBankAccountHV",
    CLICKED_CONTINUE_HV: "clickedContinueHv",
    CLICKED_CHANGE_ACCOUNT_HV: "clickedChangeAccountHv",
    CLICKED_RETRY_HV: "clickedRetryHv",
    CLICKED_CANCEL_HV: "clickedCancelHv",
    CLICKED_BANK_DETAILS_HV_OUTSIDE_INDIA: "clickedBankDetailsHvOutsideIndia",
    CLICKED_BANK_DETAILS_HV_NOT_WORKING: "clickedBankDetailsHvNotWorking",
    CLICKED_BANK_DETAILS_HV_MAX_RETRY: "clickedBankDetailsHvMaxRetry",
    CLICKED_YES_EXIT_HV: "clickedYesExitHv",
    CLICKED_NO_CONTINUE_HV: "clickedNoContinueHv",
    CLICKED_SKIP_DEMAT: "clickedSkipDemat",
    CLICKED_CONTINUE_DEMAT: "clickedContinueDemat",
    VIEWED_KYC_VERIFIED_PAGE: "kycVerified",
    VIEWED_MANUAL_VERIFICATION_PAGE: "kycManualVerification",
    CLICKED_BOOK_CONSULTATION_VERIFIED: "clickedBookConsultationVerified",
    CLICKED_EXPLORE_OPP_VERIFIED: "clickedExploreOppVerified",
    CLICKED_EXPLORE_OPP_MANUAL: "clickedExploreOppManual",
    CLICKED_BOOK_CONSULTATION_MANUAL: "clickedBookConsultationManual",
    CLICKED_REINVESTMENT_DOC_SIGN_E_SIGN_NOW: 'clicked_reinv_doc_sign_e_sign_now',
    CLICKED_REINVESTMENT_DOC_SIGN_RETRY_E_SIGN: 'clicked_reinv_doc_sign_retry_e_sign',
    CLICKED_REINVESTMENT_DOC_SIGN_ALREADY_SIGN_GO: 'clicked_reinv_doc_sign_already_sign_go',
    CLICKED_REINVESTMENT_DOC_SIGN_EXPIRED_GO: 'clicked_reinv_doc_sign_expired_go',
    CLICKED_REINVESTMENT_DOC_SIGN_LOGOUT_OK: 'clicked_reinv_doc_sign_logout_ok',
    CLICKED_REINVESTMENT_DOC_SIGN_SUCCESS_DETAILS: 'clicked_reinv_doc_sign_success_details',
    CLICKED_REINVESTMENT_DOC_SIGN_DOWNLOAD_DOCUMENT: 'clicked_reinv_doc_sign_download_doc',
    CLICKED_REINVESTMENT_DOC_SIGN_SUCCESS_EXPLORE_OPP: 'clicked_reinv_doc_sign_succ_explore_opp',
    CLICKED_REINVESTMENT_DOC_SIGN_PROGRESS_EXPLORE_OPP: 'clicked_reinv_doc_sign_prog_explore_opp',
    CLICKED_REINVESTMENT_DETAILS_E_SIGN_NOW: 'clicked_reinv_details_e_sign_now',
    CLICKED_OPP_REINVESTMENT_E_SIGN_NOW: 'clicked_opp_reinv_e_sign_now',
    CLICKED_MY_INV_DETAILS_REINVESTMENT_E_SIGN_NOW: 'clicked_my_inv_details_reinv_e_sign_now',
    CLICKED_PROFILE_EDIT_NUMBER: "clickedProfileEditNumber",
    CLICKED_PROFILE_EDIT_EMAIL: "clickedProfileEditEmail",
    CLICKED_PROFILE_CHAT_WITH_RM: "clickedProfileChatWithRm",
    CLICKED_PROFILE_BOOK_CONSULTATION: "clickedProfileBookConsultation",
    CLICKED_PROFILE_COMPLETE_KYC: "clickedProfileCompleteKyc",
    CLICKED_PROFILE_ADD_DEMAT: "clickedProfileAddDemat",
    CLICKED_PROFILE_ADD_NOMINEE: "clickedProfileAddNominee",
    CLICKED_NEWS_LETTERS_SIDENAV_MOBILE_WEB_NAVIGATION: "clicked_news_letters_sidenav_mobile_web_navigation",
    CLICKED_NEWS_LETTERS_LINK: 'clicked_news_letters_link',
    VIEWED_NEWS_LETTERS_PAGE: "viewed_news_letters_page",
    CLICKED_NEWS_LETTERS_READ_NOW: "clicked_news_letters_read_now",
    SIGNUP_FORM_START: "signup_form_start"
};
